<template>
  <v-container fluid>
    <v-row v-if="!loading">
      <v-col cols="12">
        <tarjeta-datos
          :datos="[
            {
              nombre: 'CUPS',
              valor: [datos.CodigoCUPS, datos.NombreCalle]
                .filter((item) => item != null)
                .join(' - '),
            },
            { nombre: 'Codigo CUPS', valor: datos.CodigoCUPS },
          ]"
        ></tarjeta-datos>
      </v-col>

      <v-col cols="12" class="col-lg-4 col-sm-6">
        <tarjeta-datos
          header
          titulo="Dirección"
          icon="lightbulb"
          :datos="[
            { nombre: 'Provincia', valor: datos.TextoProvincia },
            { nombre: 'Ciudad', valor: datos.TextoCiudad },
            { nombre: 'Calle', valor: datos.NombreCalle },
            { nombre: 'Número', valor: datos.Numero },
            { nombre: 'Portal', valor: datos.TextoPortal },
            { nombre: 'Aclarador', valor: datos.Aclarador },
            { nombre: 'Distribuidora', valor: datos.Distribuidora },
          ]"
        ></tarjeta-datos>
        <tarjeta-datos
          class="mt-6"
          header
          titulo="Varios"
          icon="chevron-double-down"
          :datos="[
            { nombre: 'Ref Catastral', valor: datos.RefCatastral },
            { nombre: 'CNAE', valor: datos.TextoCnae },
            { nombre: 'Observaciones', valor: datos.Observaciones },
          ]"
        ></tarjeta-datos>
      </v-col>
      <v-col cols="12" class="col-lg-8 col-sm-6">
        <tarjeta-datos header titulo="Boletín" icon="note">
          <v-row no-gutters>
            <v-col class="pa-0 col-md-6" cols="12">
              <tarjeta-datos
                borderless
                :datos="[
                  { nombre: 'Nº Boletín', valor: datos.NumBoletin },
                  { nombre: 'Titulación', valor: datos.Titulacion },
                  { nombre: 'Colegio', valor: datos.Colegio },
                  {
                    nombre: 'Titular Certificado',
                    valor: datos.TitularCertificado,
                  },
                  { nombre: 'Director Obra', valor: datos.DirectorObra },
                  { nombre: 'Org. Control', valor: datos.Organismo },
                  { nombre: 'Nº CCI', valor: datos.CCI },
                  { nombre: 'Modalidad', valor: datos.Modalidad },
                  {
                    nombre: 'Observaciones Servicios Territoriales',
                    valor: datos.ObsTerritorial,
                  },
                ]"
              ></tarjeta-datos>
            </v-col>
            <v-col class="pa-0 col-md-6" cols="12">
              <tarjeta-datos
                borderless
                :datos="[
                  { nombre: 'Potencia Max Boletin', valor: datos.PotMax },
                  { nombre: 'Instalación', valor: datos.FechaIns },
                  { nombre: 'Vigencia', valor: datos.FechaVigencia },
                  { nombre: 'Fecha Favorable', valor: datos.FechaOk },
                  { nombre: null, valor: '' },
                  { nombre: 'Nº Colegio', valor: datos.NumColegiado },
                  { nombre: 'Nº Notificación', valor: datos.Notificacion },
                  { nombre: 'Categoría', valor: datos.Categoria },
                  {
                    nombre: 'Observaciones Instalación',
                    valor: datos.ObsInstalacion,
                  },
                ]"
              ></tarjeta-datos>
            </v-col>
          </v-row>
        </tarjeta-datos>
      </v-col>
      <v-col cols="12">
        <v-data-table
          v-if="datos"
          item-key="CodigoCUPS"
          :items="datos.datosSIPS"
          :headers="$data[`clientesSipsHeaders${$store.getters.getEntorno}`]"
          show-select class="fixed-checkbox"
          v-model="selected"
          checkbox-color="secondary"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [10, 25, 50],
          }"
        >
          <template v-slot:body.prepend="{ headers }">
            <table-filters :headers="headers" :items="datos.datosSIPS" v-model="inlineFilters"></table-filters>
          </template>

          <template v-slot:item.tarifa="{ item }">
            <v-chip v-show="item.tarifa" label small>
              <s>{{ item.tarifaOld }}</s>
              <span>{{ item.tarifa }}</span>
            </v-chip>
          </template>

          <template v-slot:item.total="{ item }">
            <span v-show="item.total != null && item.total > 0" v-text="`${item.total} KWh`" />
          </template>
          <template v-slot:item.a1="{ item }">
            <span v-show="item.a1 != null && item.a1 > 0" v-text="`${item.a1} KWh`" />
          </template>
          <template v-slot:item.a2="{ item }">
            <span v-show="item.a2 != null && item.a2 > 0" v-text="`${item.a2} KWh`" />
          </template>
          <template v-slot:item.a3="{ item }">
            <span v-show="item.a3 != null && item.a3 > 0" v-text="`${item.a3} KWh`" />
          </template>
          <template v-slot:item.a4="{ item }">
            <span v-show="item.a4 != null && item.a4 > 0" v-text="`${item.a4} KWh`" />
          </template>
          <template v-slot:item.a5="{ item }">
            <span v-show="item.a5 != null && item.a5 > 0" v-text="`${item.a5} KWh`" />
          </template>
          <template v-slot:item.a6="{ item }">
            <span v-show="item.a6 != null && item.a6 > 0" v-text="`${item.a6} KWh`" />
          </template>

          <template v-slot:item.auxCaudalMedioEnWhAnual="{ item }">
            <span
              v-show="
                item.auxCaudalMedioEnWhAnual != null &&
                item.auxCaudalMedioEnWhAnual > 0
              "
              v-text="`${item.auxCaudalMedioEnWhAnual} KWh`"
            />
          </template>
          <template v-slot:item.CaudalHorarioEnWh="{ item }">
            <span
              v-show="
                item.CaudalHorarioEnWh != null && item.CaudalHorarioEnWh > 0
              "
              v-text="`${item.CaudalHorarioEnWh} KWh`"
            />
          </template>
          <template v-slot:item.CaudalMaximoDiarioEnWh="{ item }">
            <span
              v-show="
                item.CaudalMaximoDiarioEnWh != null &&
                item.CaudalMaximoDiarioEnWh > 0
              "
              v-text="`${item.CaudalMaximoDiarioEnWh} KWh`"
            />
          </template>

          <template v-slot:item.FechaUltimoCambioComercializador="{ item }">
            <span v-text="parseDate(item.FechaUltimoCambioComercializador)"></span>
          </template>
          <template v-slot:item.FechaUltimoMovimientoContrato="{ item }">
            <span v-text="parseDate(item.FechaUltimoMovimientoContrato)"></span>
          </template>
          <template v-slot:item.FechaEmisionBIE="{ item }">
            <span v-text="parseDate(item.FechaEmisionBIE)"></span>
          </template>
          <template v-slot:item.PotenciaMaximaBIEW="{ item }">
            <span>{{ (item.PotenciaMaximaBIEW / 1000) }} kW</span>
          </template>

          <template v-slot:footer.prepend>
            <v-chip
              :color="
                $store.getters.getEntorno == 'Luz'
                  ? 'amber darken-2'
                  : 'deep-orange'
              "
              outlined
            >
              <v-icon left>
                {{
                  $store.getters.getEntorno == "Luz"
                    ? "mdi-lightning-bolt"
                    : "mdi-fire"
                }}
              </v-icon>
              <span>{{ $store.getters.getEntorno }}</span>
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div v-else class="d-flex justify-center pa-6">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import { processToken, calcularConsumoAnual, calcularConsumoAnualGas, obtenerNombreTarifaML, obtenerNombreTarifaGas, perColumnFilter, parseDate } from "@/utils/index.js";

export default {
  components: {
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
		TableFilters: () => import("@/components/TableFilters.vue"),
  },
  props: ['CodigoCups'],
  data() {
    return {
      cups: this.CodigoCups || this.$route.query.cups,
      datos: null,
      loading: true,
      inlineFilters: {},
      clientesSipsHeadersLuz: [
        { text: "CUPS", value: "CodigoCUPS", },
        { text: "Municipio", value: "DesMunicipioPS", },
        { text: "Municipio CP", value: "CodigoPostalPS", align: "end", },
        { text: "Distribuidora", value: "NombreEmpresaDistribuidora", },
        { text: "CodDistribuidora", value: "CodigoEmpresaDistribuidora", align: "end", },
        { text: "Tarifa", value: "tarifa", dataType: "select", },
        { text: "Consumo Total", value: "total", align: "end", },
        { text: "Consumo P1 anual", value: "a1", align: "end", },
        { text: "Consumo P2 anual", value: "a2", align: "end", },
        { text: "Consumo P3 anual", value: "a3", align: "end", },
        { text: "Consumo P4 anual", value: "a4", align: "end", },
        { text: "Consumo P5 anual", value: "a5", align: "end", },
        { text: "Consumo P6 anual", value: "a6", align: "end", },
        { text: "P1", value: "PotenciasContratadasEnWP1", align: "end", },
        { text: "P2", value: "PotenciasContratadasEnWP2", align: "end", },
        { text: "P3", value: "PotenciasContratadasEnWP3", align: "end", },
        { text: "P4", value: "PotenciasContratadasEnWP4", align: "end", },
        { text: "P5", value: "PotenciasContratadasEnWP5", align: "end", },
        { text: "P6", value: "PotenciasContratadasEnWP6", },
        { text: "Potencia máxima instalación", value: "PotenciaMaximaBIEW", },
        { text: "Último cambio comercializadora", value: "FechaUltimoCambioComercializador", },
        { text: "Fecha emisión BIE", value: "FechaEmisionBIE", },
      ].map((header) => {
        return {
          class: "text-no-wrap sticky-header",
          cellClass: "pa-2 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        };
      }),
      clientesSipsHeadersGas: [
        { text: "CUPS", value: "CodigoCUPS", },
        { text: "Municipio", value: "DesMunicipioPS", },
        { text: "Municipio CP", value: "CodigoPostalPS", align: "end", },
        { text: "Distribuidora", value: "NombreEmpresaDistribuidora", },
        // { text: "CodDistribuidora", value: "CodigoEmpresaDistribuidora", align: "end", },
        { text: "Tarifa", value: "tarifa", dataType: "select", },
        { text: "CAE", value: "auxCaudalMedioEnWhAnual", align: "end", },
        { text: "Caudal Horario", value: "CaudalHorarioEnWh", align: "end", },
        { text: "Caudal Máximo", value: "CaudalMaximoDiarioEnWh", align: "end", },
        { text: "Último cambio comercializadora", value: "FechaUltimoCambioComercializador", },
        { text: "Último cambio BIE", value: "FechaEmisionBIE", },
        { text: "Presión", value: "CodigoPresion", },
        { text: "Último Cambio Contrato", value: "FechaUltimoMovimientoContrato", },
      ].map((header) => {
        return {
          class: "text-no-wrap sticky-header",
          cellClass: "pa-2 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        };
      }),
      inlineFilters: {},
      selected: [],
    };
  },
  methods: {
    obtenerNombreTarifaML,
    parseDate
  },
  async mounted() {
    let str = this.$store.getters.getDatosEmpresa;

    const { IdUsuario, IdAgente } = str.iss;
    const IdEmpresa = this.$store.getters.getIdEmpresa

    const { data } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/CUPS/GetCodigoCUPS/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/CodigoCUPS/${this.cups}`,
    })
      this.datos = processToken(data).iss;


			axios({
				url:
					this.$store.getters.getEntorno == "Luz"
						? "https://sips.sigeenergia.com:61843/SIPSAPIvLast/api/v2/ClientesSips/GetClientesPost"
						: "https://sips.sigeenergia.com:61843/SIPSAPIvLast/api/v2/ClientesSipsGas/GetClientesPost",
				method: "POST",
				data: {
					CodigoCUPS: this.datos.CodigoCUPS,
					NombreEmpresaDistribuidora: "",
					MunicipioPS: "",
					CodigoProvinciaPS: "",
					CodigoPostalPS: "",
					CodigoTarifaATREnVigor: "",
					ListCUPS: "",
					LoadAllDatosCliente: true,
					LoadConsumos: true,
					IsExist: true,
				},
			}).then((res) => {
          console.error(this.loading)

				try {res.data.ClientesSips = res.data.ClientesSips.map((cliente) => {
					let consumos;
					if (this.$store.getters.getEntorno == "Luz")
						consumos = calcularConsumoAnual(
							res.data.ConsumosSips,
							cliente.CodigoCUPS,
							cliente.CodigoTarifaATREnVigor
						);
					else
						consumos = calcularConsumoAnualGas(
							res.data.ConsumosSips,
							cliente.CodigoCUPS,
							cliente.CodigoTarifaATREnVigor
						);

					cliente.tarifa = this.obtenerNombreTarifaML(
						cliente.CodigoTarifaATREnVigor
					).actual;
					cliente.tarifaOld = this.obtenerNombreTarifaML(
						cliente.CodigoTarifaATREnVigor
					).antes;
					if (cliente.CodigoPeajeEnVigor)
						cliente.tarifa = obtenerNombreTarifaGas(cliente.CodigoPeajeEnVigor)
					if (
						"004" == cliente.CodigoTarifaATREnVigor ||
						"006" == cliente.CodigoTarifaATREnVigor
					) {
						cliente.PotenciasContratadasEnWP3 =
							cliente.PotenciasContratadasEnWP1;
						cliente.PotenciasContratadasEnWP2 = null;
					}

					return { ...cliente, ...consumos };
				});}
        catch (e) {
          console.error(e)
        }

				this.datos.datosSIPS = [ ...res.data.ClientesSips, ];
				this.loading = false;

			});
  },
};
</script>

<style>
</style>