var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(!_vm.loading)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('tarjeta-datos',{attrs:{"datos":[
          {
            nombre: 'CUPS',
            valor: [_vm.datos.CodigoCUPS, _vm.datos.NombreCalle]
              .filter(function (item) { return item != null; })
              .join(' - '),
          },
          { nombre: 'Codigo CUPS', valor: _vm.datos.CodigoCUPS } ]}})],1),_c('v-col',{staticClass:"col-lg-4 col-sm-6",attrs:{"cols":"12"}},[_c('tarjeta-datos',{attrs:{"header":"","titulo":"Dirección","icon":"lightbulb","datos":[
          { nombre: 'Provincia', valor: _vm.datos.TextoProvincia },
          { nombre: 'Ciudad', valor: _vm.datos.TextoCiudad },
          { nombre: 'Calle', valor: _vm.datos.NombreCalle },
          { nombre: 'Número', valor: _vm.datos.Numero },
          { nombre: 'Portal', valor: _vm.datos.TextoPortal },
          { nombre: 'Aclarador', valor: _vm.datos.Aclarador },
          { nombre: 'Distribuidora', valor: _vm.datos.Distribuidora } ]}}),_c('tarjeta-datos',{staticClass:"mt-6",attrs:{"header":"","titulo":"Varios","icon":"chevron-double-down","datos":[
          { nombre: 'Ref Catastral', valor: _vm.datos.RefCatastral },
          { nombre: 'CNAE', valor: _vm.datos.TextoCnae },
          { nombre: 'Observaciones', valor: _vm.datos.Observaciones } ]}})],1),_c('v-col',{staticClass:"col-lg-8 col-sm-6",attrs:{"cols":"12"}},[_c('tarjeta-datos',{attrs:{"header":"","titulo":"Boletín","icon":"note"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 col-md-6",attrs:{"cols":"12"}},[_c('tarjeta-datos',{attrs:{"borderless":"","datos":[
                { nombre: 'Nº Boletín', valor: _vm.datos.NumBoletin },
                { nombre: 'Titulación', valor: _vm.datos.Titulacion },
                { nombre: 'Colegio', valor: _vm.datos.Colegio },
                {
                  nombre: 'Titular Certificado',
                  valor: _vm.datos.TitularCertificado,
                },
                { nombre: 'Director Obra', valor: _vm.datos.DirectorObra },
                { nombre: 'Org. Control', valor: _vm.datos.Organismo },
                { nombre: 'Nº CCI', valor: _vm.datos.CCI },
                { nombre: 'Modalidad', valor: _vm.datos.Modalidad },
                {
                  nombre: 'Observaciones Servicios Territoriales',
                  valor: _vm.datos.ObsTerritorial,
                } ]}})],1),_c('v-col',{staticClass:"pa-0 col-md-6",attrs:{"cols":"12"}},[_c('tarjeta-datos',{attrs:{"borderless":"","datos":[
                { nombre: 'Potencia Max Boletin', valor: _vm.datos.PotMax },
                { nombre: 'Instalación', valor: _vm.datos.FechaIns },
                { nombre: 'Vigencia', valor: _vm.datos.FechaVigencia },
                { nombre: 'Fecha Favorable', valor: _vm.datos.FechaOk },
                { nombre: null, valor: '' },
                { nombre: 'Nº Colegio', valor: _vm.datos.NumColegiado },
                { nombre: 'Nº Notificación', valor: _vm.datos.Notificacion },
                { nombre: 'Categoría', valor: _vm.datos.Categoria },
                {
                  nombre: 'Observaciones Instalación',
                  valor: _vm.datos.ObsInstalacion,
                } ]}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.datos)?_c('v-data-table',{staticClass:"fixed-checkbox",attrs:{"item-key":"CodigoCUPS","items":_vm.datos.datosSIPS,"headers":_vm.$data[("clientesSipsHeaders" + (_vm.$store.getters.getEntorno))],"show-select":"","checkbox-color":"secondary","footer-props":{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [10, 25, 50],
        }},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
        var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.datos.datosSIPS},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.tarifa",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.tarifa),expression:"item.tarifa"}],attrs:{"label":"","small":""}},[_c('s',[_vm._v(_vm._s(item.tarifaOld))]),_c('span',[_vm._v(_vm._s(item.tarifa))])])]}},{key:"item.total",fn:function(ref){
        var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.total != null && item.total > 0),expression:"item.total != null && item.total > 0"}],domProps:{"textContent":_vm._s(((item.total) + " KWh"))}})]}},{key:"item.a1",fn:function(ref){
        var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.a1 != null && item.a1 > 0),expression:"item.a1 != null && item.a1 > 0"}],domProps:{"textContent":_vm._s(((item.a1) + " KWh"))}})]}},{key:"item.a2",fn:function(ref){
        var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.a2 != null && item.a2 > 0),expression:"item.a2 != null && item.a2 > 0"}],domProps:{"textContent":_vm._s(((item.a2) + " KWh"))}})]}},{key:"item.a3",fn:function(ref){
        var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.a3 != null && item.a3 > 0),expression:"item.a3 != null && item.a3 > 0"}],domProps:{"textContent":_vm._s(((item.a3) + " KWh"))}})]}},{key:"item.a4",fn:function(ref){
        var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.a4 != null && item.a4 > 0),expression:"item.a4 != null && item.a4 > 0"}],domProps:{"textContent":_vm._s(((item.a4) + " KWh"))}})]}},{key:"item.a5",fn:function(ref){
        var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.a5 != null && item.a5 > 0),expression:"item.a5 != null && item.a5 > 0"}],domProps:{"textContent":_vm._s(((item.a5) + " KWh"))}})]}},{key:"item.a6",fn:function(ref){
        var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.a6 != null && item.a6 > 0),expression:"item.a6 != null && item.a6 > 0"}],domProps:{"textContent":_vm._s(((item.a6) + " KWh"))}})]}},{key:"item.auxCaudalMedioEnWhAnual",fn:function(ref){
        var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(
              item.auxCaudalMedioEnWhAnual != null &&
              item.auxCaudalMedioEnWhAnual > 0
            ),expression:"\n              item.auxCaudalMedioEnWhAnual != null &&\n              item.auxCaudalMedioEnWhAnual > 0\n            "}],domProps:{"textContent":_vm._s(((item.auxCaudalMedioEnWhAnual) + " KWh"))}})]}},{key:"item.CaudalHorarioEnWh",fn:function(ref){
            var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(
              item.CaudalHorarioEnWh != null && item.CaudalHorarioEnWh > 0
            ),expression:"\n              item.CaudalHorarioEnWh != null && item.CaudalHorarioEnWh > 0\n            "}],domProps:{"textContent":_vm._s(((item.CaudalHorarioEnWh) + " KWh"))}})]}},{key:"item.CaudalMaximoDiarioEnWh",fn:function(ref){
            var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(
              item.CaudalMaximoDiarioEnWh != null &&
              item.CaudalMaximoDiarioEnWh > 0
            ),expression:"\n              item.CaudalMaximoDiarioEnWh != null &&\n              item.CaudalMaximoDiarioEnWh > 0\n            "}],domProps:{"textContent":_vm._s(((item.CaudalMaximoDiarioEnWh) + " KWh"))}})]}},{key:"item.FechaUltimoCambioComercializador",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaUltimoCambioComercializador))}})]}},{key:"item.FechaUltimoMovimientoContrato",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaUltimoMovimientoContrato))}})]}},{key:"item.FechaEmisionBIE",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaEmisionBIE))}})]}},{key:"item.PotenciaMaximaBIEW",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s((item.PotenciaMaximaBIEW / 1000))+" kW")])]}},{key:"footer.prepend",fn:function(){return [_c('v-chip',{attrs:{"color":_vm.$store.getters.getEntorno == 'Luz'
                ? 'amber darken-2'
                : 'deep-orange',"outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.$store.getters.getEntorno == "Luz" ? "mdi-lightning-bolt" : "mdi-fire")+" ")]),_c('span',[_vm._v(_vm._s(_vm.$store.getters.getEntorno))])],1)]},proxy:true}],null,false,1225775766),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)],1):_c('div',{staticClass:"d-flex justify-center pa-6"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }